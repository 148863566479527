<template>
  <component-frame title="Help">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#help'">Form field help</a></li>
          <li><a href="#" v-scroll-to="'#popover'">Popover</a></li>
          <li><a href="#" v-scroll-to="'#collapse'">Help Collapse</a></li>
          <li>
            <a href="#" v-scroll-to="'#help-accordion'">Help Accordion</a>
          </li>
        </ul>
      </div>
    </div>

    <h3 id="help">Form Field Help</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <label for="email">Field name</label>
            <input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email" />
            <p class="form-text text-muted">
              Short contextual help text to guide the user
            </p>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;label for="email"&gt;Field name&lt;/label&gt;
  &lt;input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email"&gt;
  &lt;p class="form-text text-muted"&gt;Short contextual help text to guide the user&lt;/p&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            For short important contextual help use a help text line below the field
          </li>
          <li>
            Do not use placeholder text for help. It dissapears when field is in focus and some users might confuse it as already filled
          </li>
          <li>
            A tooltip after the field label can be used for non-critical longer help text
          </li>
        </ul>
      </div>
    </div>

    <h3 id="popover">Popover</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <label for="popoverField">
              Field label
              <a
                href="#"
                data-toggle="popover"
                data-placement="top"
                data-content="
                        Help Text"
                v-popover
                ><svg class="icon icon-help">
                  <use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg
              ></a>
            </label>
            <input type="text" class="form-control" id="popoverField" />
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;label for="tooltipField"&gt;
    Field label
    &lt;a href="#" data-toggle="popover" data-placement="top" data-content="
        Help Text" v-popover&gt;&lt;svg class="icon icon-help"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-help"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/a&gt;
  &lt;/label&gt;
  &lt;input type="text" class="form-control" id="tooltipField"&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Useful for brifly clarifying a term or concept that is not essential to performing a task
          </li>
          <li>
            Always use popovers for inline help icons especially next to form labels, except if the character count exceeds 500 characters. A help collapse can
            be used in this scenario
          </li>
          <li>Important help or tips should not be hidden inside a tooltip</li>
          <li>If the help text long, a collapse can be used instead</li>
        </ul>
      </div>
    </div>

    <h3 id="collapse">Help collapse</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <h4>Help collapse on white background</h4>

          <a class="collapse-toggle" href="#collapse1" data-toggle="collapse" aria-expanded="false" aria-controls="collapse1">
            <svg class="icon icon-help">
              <use xlink:href="/icons/symbol-defs.svg#icon-help"></use>
            </svg>
            <svg class="icon icon-close-circle">
              <use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use>
            </svg>
            Help collapse 1
          </a>
          <div class="collapse" id="collapse1">
            <div class="card bg-light mt-1">
              <div class="card-body">
                <h4>This is an example of a help text collapse.</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed
                  consequat. Cras congue elit eget dolor aliquam dignissim.
                </p>
              </div>
            </div>
          </div>

          <h4 class="mt-4">Help collapse on grey background</h4>
          <div class="bg-medium p-3">
            <a class="collapse-toggle" href="#collapse2" data-toggle="collapse" aria-expanded="false" aria-controls="collapse2">
              <svg class="icon icon-help">
                <use xlink:href="/icons/symbol-defs.svg#icon-help"></use>
              </svg>
              <svg class="icon icon-close-circle">
                <use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use>
              </svg>
              Help collapse on grey background
            </a>
            <div class="collapse" id="collapse2">
              <div class="card bg-white mt-1">
                <div class="card-body">
                  <h4>This is an example of a help text collapse.</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed
                    consequat. Cras congue elit eget dolor aliquam dignissim.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
// White background
&lt;a class="collapse-toggle" href="#collapse1" data-toggle="collapse" aria-expanded="false" aria-controls="collapse1"&gt;
  &lt;svg class="icon icon-help"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-help"&gt;&lt;/use&gt;&lt;/svg&gt;
  &lt;svg class="icon icon-close-circle"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close-circle"&gt;&lt;/use&gt;&lt;/svg&gt;
  Help collapse 1
&lt;/a&gt;
&lt;div class="collapse" id="collapse1"&gt;
  &lt;div class="card bg-light mt-1"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4&gt;This is an example of a help text collapse.&lt;/h4&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed consequat. Cras congue elit eget dolor aliquam dignissim.&lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;

// Grey background
&lt;a class="collapse-toggle" href="#collapse2" data-toggle="collapse" aria-expanded="false" aria-controls="collapse2"&gt;
  &lt;svg class="icon icon-help"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-help"&gt;&lt;/use&gt;&lt;/svg&gt;
  &lt;svg class="icon icon-close-circle"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close-circle"&gt;&lt;/use&gt;&lt;/svg&gt;
  Help collapse on grey background
&lt;/a&gt;
&lt;div class="collapse" id="collapse2"&gt;
  &lt;div class="card bg-white mt-1"&gt;
    &lt;div class="card-body"&gt;
      &lt;h4&gt;This is an example of a help text collapse.&lt;/h4&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed consequat. Cras congue elit eget dolor aliquam dignissim.&lt;/p&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Used for providing longer contextual help usually preceeding a form or a block of content
          </li>
          <li>
            If the help text exceeds 500 characters, this pattern can be used for form labels. In this case, the collapse should expand below the input field to
            maintain the relationship between the label and input field
          </li>
        </ul>
      </div>
    </div>

    <h3 id="help-accordion">Help Accordion</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div id="accordion">
            <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              <svg class="icon icon-arrow_right collapse-icon">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
              Help item 1
            </button>
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="collapse-body">
                Help content
              </div>
            </div>

            <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <svg class="icon icon-arrow_right collapse-icon">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
              Help item 2
            </button>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="collapse-body">
                Help content
              </div>
            </div>

            <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <svg class="icon icon-arrow_right collapse-icon">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
              </svg>
              Help item 3
            </button>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="collapse-body">
                Help content
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div id="accordion" class="border-top"&gt;
  &lt;button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"&gt;
    &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
    Help item 1
  &lt;/button&gt;
  &lt;div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion"&gt;
    &lt;div class="collapse-body"&gt;
      Help content 1
    &lt;/div&gt;
  &lt;/div&gt;

  &lt;button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"&gt;
    &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
    Help item 2
  &lt;/button&gt;
  &lt;div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion"&gt;
    &lt;div class="collapse-body"&gt;
      Help content 2
    &lt;/div&gt;
  &lt;/div&gt;

  &lt;button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"&gt;
    &lt;svg class="icon icon-arrow_right collapse-icon"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"&gt;&lt;/use&gt;&lt;/svg&gt;
    Help item 3
  &lt;/button&gt;
  &lt;div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion"&gt;
    &lt;div class="collapse-body"&gt;
      Help content 3
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Accordions can be used for help as well in cases where there is too much help content for a single tooltip of help collapse
          </li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      title: "Help",
    };
  },
};
</script>
